import "../ContactForm/ContactForm.css";
import { useState } from "react";
//import SendEmail from "../SendEmail/SendEmail.js";

export default function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  // Function to validate individual fields
  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
      case "lastName":
        if (!value.trim())
          return `Please tell me your ${
            name === "firstName" ? "first" : "last"
          } name!`;
        if (!/^[A-Za-z]+( [A-Za-z]+)?$/.test(value))
          return "Only alphabets are allowed!.";
        return "";
      case "email":
        if (!value.trim()) return "Please tell me your email address!";
        if (!/\S+@\S+\.\S+/.test(value)) return "Please enter valid email!";
        return "";
      case "message":
        if (!value.trim())
          return "Please provide me with more details about your inquiry!";
        return "";
      default:
        return "";
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Optionally clear errors as user types
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: "" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate all fields before submitting
    let formIsValid = true;
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
        formIsValid = false;
      }
    });
    setErrors(newErrors);
    if (!formIsValid) return;
    // Proceed with form submission logic here

    console.log(formData);
    alert("Form submitted!");
  };

  return (
    <div className="contact-form">
      <h2 className="form-header">Want to connect. Drop a message!</h2>
      <form onSubmit={handleSubmit} noValidate>
        <div className="form-row">
          <div className="half-width">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.firstName ? "input-error" : ""}
            />
            {errors.firstName && <p className="error">{errors.firstName}</p>}
          </div>
          <div className="half-width">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.lastName ? "input-error" : ""}
            />
            {errors.lastName && <p className="error">{errors.lastName}</p>}
          </div>
        </div>
        <div className="full-width">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.email ? "input-error" : ""}
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div className="full-width">
          <label>Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.message ? "input-error" : ""}
          ></textarea>
          {errors.message && <p className="error">{errors.message}</p>}
        </div>
        <button type="submit">SEND MESSAGE</button>
      </form>
    </div>
  );
}
