import "../Navbar/Navbar.css";
import ToggleButton from "../ToggleButton/ToggleButton";
export default function Navbar() {
  // const chnageTheme = () => {
  //   document.body.classList.toggle("body-light-theme");
  // };

  return (
    <nav>
      <div className="navbar">
        {/* <a href="index.html">Neel Ratna Singh</a> */}
        <ul className="navitem">
          {/* <li>
              <a href="#services">Services</a>
            </li>
            <li>
              <a href="#blogs">Blogs</a>
            </li>
          <li>
            <a href="#contact">Contact</a>
          </li> */}
          <li>
            <ToggleButton />
          </li>
        </ul>
      </div>
    </nav>
  );
}
