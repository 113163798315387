import profilePicture from "./NeelRatnaSinghWebProfile.jpg";
import "../AboutMe/AboutMe.css";

export default function AboutMe() {
  return (
    <div className="about-me-container">
      <div className="about-me-content">
        {/* <h2 className="form-header">About Me</h2> */}
        <p className="justify-para">
          <p className="info">
            Hey, I'm <span className="myname">Neel Ratna Singh</span>, a
            Software Engineer by passion and profession. I am currently working
            as a Salesforce Developer. It's been 5 years working in IT sector.
          </p>
          <p className="info">
            To know more about me, my technical skills and professional journey,
            let's connect on{" "}
            <a
              href="https://www.linkedin.com/in/neelratnasingh"
              target="_blank"
              rel="external noopener noreferrer"
              className="linkedin"
            >
              LinkedIn
            </a>
            <span class="heart"> &hearts;</span>.
          </p>
        </p>
      </div>
      <div className="photo-container">
        <img
          src={profilePicture}
          alt="Neel Ratna Singh"
          className="profile-photo"
        />
      </div>
    </div>
  );
}
