import "../ToggleButton/ToggleButton.css";
import { useState, useEffect } from "react";

export default function ToggleButton() {
  const isLightModeSaved = localStorage.getItem("isLightMode") === "true";
  const [isLightMode, setisLightMode] = useState(isLightModeSaved);

  useEffect(() => {
    localStorage.setItem("isLightMode", isLightMode);
    document.body.classList.toggle("light-mode-theme", isLightMode);
  }, [isLightMode]);

  const handleToggle = () => {
    setisLightMode(!isLightMode);
  };

  return (
    <div className="checkbox-wrapper-54">
      <label className="switch">
        <input type="checkbox" onChange={handleToggle} checked={isLightMode} />
        <span className="slider"></span>
      </label>
    </div>
  );
}
