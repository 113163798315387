import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import ContactForm from "./components/ContactForm/ContactForm";
import AboutMe from "./components/AboutMe/AboutMe";

function App() {
  return (
    <div>
      <header>
        {/* This is a placeholder for upcoming component to brodcast the information*/}
        <Navbar />
      </header>
      <main>
        <AboutMe />
        <ContactForm />
      </main>
      <footer></footer>
    </div>
  );
}

export default App;
